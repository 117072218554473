import { useLocation } from 'ts/base/hooks/UseLocation';
import { StringUtils } from 'ts/commons/StringUtils';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns the currently active perspective. */
export function useActivePerspective(): ETeamscalePerspective {
	const location = useLocation();
	const perspectivePathAndView = StringUtils.stripPrefix(location.pathname, '/');
	const perspectivePathAndViewParts = perspectivePathAndView.split('/');
	const perspectivePageName = decodeURIComponent(perspectivePathAndViewParts.shift()!);
	return ETeamscalePerspective.values.find(perspective => perspective.page === perspectivePageName + '.html')!;
}
